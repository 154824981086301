<template>
  <a-drawer
    :placement="drawerPlacement"
    :visible="visibleDrawer"
    class="topic-management-drawer"
    :class="dynamicClass + openiscussionCollapse"
    width="46.5rem"
    title="Topic Management"
    @close="closeTopicManagementDrawer()"
  >
    <a-tabs v-model:activeKey="activeKey" class="topic-management-tabs">
      <a-tab-pane v-if="topicToBeManage?.is_private" key="1">
        <template #tab>
          <span class="tabs-name">Admin</span>
        </template>
        <div class="topic-management">
          <div class="topic-management__body">
            <div class="skeleton-loader" v-if="loader">
              <a-skeleton
                active
                :title="null"
                :paragraph="{ rows: 1, width: ['100%'] }"
                class="input-skeleton"
              />
              <template v-for="skeleton in 3" :key="skeleton">
                <a-skeleton
                  active
                  :title="null"
                  :paragraph="{ rows: 1, width: ['100%'] }"
                  class="input-skeleton"
                />
                <a-skeleton
                  active
                  :title="null"
                  :paragraph="{ rows: 3, width: ['23%', '23%', '23%'] }"
                  class="user-card-skeleton"
                />
              </template>
            </div>
            <topic-management
              v-else
              :topicToBeManage="topicToBeManage"
              @closeDrawer="closeTopicManagementDrawer()"
            >
            </topic-management>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2">
        <template #tab>
          <span class="tabs-name">Filters</span>
        </template>
        <div class="topic-management">
          <div class="topic-management__body">
            <topic-filters @closeDrawer="closeTopicManagementDrawer()"></topic-filters>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import TopicManagement from "./TopicManagement.vue";
import TopicFilters from "../../BaseComponents/sourcesFilters/TopicFilters.vue";

export default {
  props: {
    visibleDrawer: Boolean,
    drawerPlacement: String,
    topicToBeManage: Object,
  },
  components: {
    TopicManagement,
    TopicFilters,
  },
  watch: {
    drawerPlacement(val) {
      if (val === "bottom") {
        this.dynamicClass = "topic-management-drawer-mobile-view";
      } else {
        this.dynamicClass = "";
      }
    },
    visibleDrawer(val) {
      if (val) {
        if (this.topicToBeManage?.is_private){
          this.getTopicGroupDetaiils();
          this.activeKey = '1'
        }
        else
          this.activeKey = '2'
      }
    },
  },
  mounted() {
    this.emitter.on('openCollapseSection', (val) => {
      val ? this.openiscussionCollapse = ' pb-iscussion-collapse-opened' : this.openiscussionCollapse = '';
  });
  },
  data() {
    return {
      activeKey: "1",
      dynamicClass: "",
      loader: true,
      openiscussionCollapse: ""
    };
  },
  computed: {
    interestGroupAudience() {
      var value = "";
      this.$store.getters["article/getInterestGroup"].forEach((element) => {
        value = element.is_private;
      });
      return value;
      },
  },
  methods: {
    closeTopicManagementDrawer() {
      this.$store.commit('discover/SET_SPECIFIC_NEWS_SOURCES', []);
      this.$emit("close-management-drawer", false);
    },
    async getTopicGroupDetaiils() {
      this.loader = true;
      const payload = {
        interest_id: this.topicToBeManage.id,
        is_private: this.interestGroupAudience,
      };
      const response = await this.$store.dispatch(
        "article/topicGroupDetails",
        payload
      );
      if (response.status == 200) {
        this.loader = false;
      }
    },
  },
};
</script>

<style lang="scss">
.topic-management-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      overflow: hidden;
      .ant-drawer-wrapper-body {
        overflow: hidden;
        .ant-drawer-header {
          padding: 3.3rem 2.5rem 2.4rem;
          border: none;
          .ant-drawer-title {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            opacity: 1;
            color: $color-black;
          }
          .ant-drawer-close {
            width: 2rem;
            height: 2rem;
            line-height: normal;
            right: 2.5rem;
            top: 3.3rem;
            color: $color-black;
            opacity: 1;
          }
        }
        .ant-drawer-body {
          height: 100%;
          padding: 0;
          overflow: hidden;
          .ant-tabs {
            height: 100%;
            padding: 0;
            .ant-tabs-bar {
              border: none;
              margin-bottom: 0;
              .ant-tabs-nav-container {
                padding-bottom: 2px;
                .ant-tabs-tab-arrow-show {
                  display: none !important;
                }
                .ant-tabs-nav-wrap {
                  border: none;
                  .ant-tabs-nav-scroll {
                    border: none;
                    overflow-x: auto;
                    margin: 0 2.5rem 2rem;
                    &::-webkit-scrollbar {
                      height: 0.3rem;
                    }
                    &::-webkit-scrollbar-track {
                      background-color: $color-white;
                      border-radius: 0.8rem;
                      height: 0.3rem;
                    }

                    &::-webkit-scrollbar-thumb {
                      background-color: $color-primary;
                      border-radius: 0.8rem;
                    }
                    @include respond(phone-x-small) {
                      &::-webkit-scrollbar {
                        display: none;
                      }
                    }
                    .ant-tabs-nav {
                      border: none;
                      padding-bottom: 0;
                      .ant-tabs-tab {
                        padding: 0.6rem 2rem;
                        border-radius: 100px;
                        font-size: 1.4rem;
                        border: 1px solid $color-dark-grey-5;
                        font-family: $font-primary;
                        color: #434343;
                        margin-right: 1.2rem;
                        margin-bottom: 0.2rem;
                      }
                      .ant-tabs-tab-active {
                        font-family: $font-primary-regular;
                        background-color: $color-dark-grey-5;
                        color: $color-black;
                        opacity: 1;
                      }
                      .ant-tabs-ink-bar {
                        display: none;
                      }
                    }
                    .ant-tabs-ink-bar {
                      display: none !important;
                    }
                  }
                }
              }
            }
            .ant-tabs-content {
              .ant-tabs-tabpane {
                height: 100%;
                overflow-x: hidden;
                .topic-management {
                  position: relative;
                  height: 100vh;
                  &__body {
                    height: 100%;
                    .skeleton-loader {
                      margin: 0 2.4rem;
                      overflow-y: auto;
                      height: calc(100vh - 18rem);
                      .input-skeleton {
                        margin-bottom: 2rem;
                        .ant-skeleton-content {
                          .ant-skeleton-paragraph {
                            margin: 0;
                            li {
                              height: 4rem;
                            }
                          }
                        }
                      }
                      .user-card-skeleton {
                        margin-bottom: 3rem;
                        .ant-skeleton-content {
                          .ant-skeleton-paragraph {
                            display: flex;
                            align-items: center;
                            gap: 2rem;
                            li {
                              height: 10rem;
                              margin: 0;
                            }
                          }
                        }
                      }
                      &::-webkit-scrollbar-track {
                        background-color: #ffffff3f;
                      }
                      &::-webkit-scrollbar {
                        width: 4px;
                        background-color: rgb(224, 224, 224);
                        border-radius: 3rem;
                      }
                      &::-webkit-scrollbar-thumb {
                        background-color: $color-primary;
                        border-radius: 3rem;
                      }
                    }
                    .sources-filter-container {
                      .sources-filter-container-body {
                        height: calc(100vh - 21.5rem);
                        .pdb-news-sources-date-filters {
                          .ant-calendar-picker {
                            > div {
                              .anticon {
                                top: 0.3rem !important;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.add-new-topic-public-private-dropdown {
  display: flex;
  align-items: center;
  @include respond(phone-x-small) {
    padding: 0;
    font-size: 2.1rem;
    line-height: 3.5rem;
    .image {
      width: 2.8rem !important;
      height: auto;
      margin-right: 1.5rem !important;
    }
  }
}
.topic-management-drawer-mobile-view {
  .ant-drawer-content-wrapper {
    height: calc(100vh - 29rem) !important;
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-header {
          padding-bottom: 3rem;
          .ant-drawer-title {
            font-size: 3.1rem;
          }
          .ant-drawer-close {
            width: 4rem;
            height: 4rem;
            .anticon {
              font-size: 3rem;
            }
          }
        }
        .ant-drawer-body {
          .topic-management-tabs {
            .ant-tabs-bar {
              .ant-tabs-nav-container {
                .ant-tabs-nav-wrap {
                  .ant-tabs-nav-scroll {
                    margin-bottom: 3rem;
                    .ant-tabs-nav {
                      .ant-tabs-tab {
                        font-size: 2rem;
                        padding: 0.8rem 2.3rem;
                      }
                    }
                  }
                }
              }
            }
          }
          .topic-management {
            &__body {
              .skeleton-loader {
                height: calc(100vh - 52rem) !important;
              }
              .sources-filter-container {
                .sources-filter-container-body {
                  height: calc(100vh - 67rem) !important;
                  @include respond(phone-x-small) {
                    &::-webkit-scrollbar {
                      display: none;
                    }
                  }
                  &__input-field {
                    .title {
                      font-size: 2.3rem;
                      line-height: 2.8rem;
                      margin-bottom: 2.5rem;
                    }
                    .all-news-sources-dropdown {
                      input {
                        font-size: 2.1rem;
                        height: 6.2rem;
                        padding: 1.8rem 2rem;
                      }
                      &__dropdown {
                        max-height: 30rem;
                        margin-top: -4.4rem !important;
                        .message {
                          font-size: 2.1rem;
                          line-height:  normal;
                          padding: 2rem;
                        }
                        &--item {
                          padding: 1.5rem 2rem !important;
                          p {
                            font-size: 2.3rem;
                            line-height: 2.8rem;
                          }
                          .ant-checkbox-wrapper {
                            .ant-checkbox {
                              .ant-checkbox-inner {
                                height: 17px;
                                width: 17px;
                                &::after {
                                  width: 5px;
                                  height: 9px;
                                }
                              }
                            }
                          }
                          .ant-checkbox-wrapper-checked {
                            .ant-checkbox-checked {
                              .ant-checkbox-inner {
                                height: 17px;
                                width: 17px;
                              }
                            }
                          }
                        }
                      }
                      &__selected-sources {
                        p {
                          font-size: 2.3rem;
                          line-height: 2.8rem;
                          margin-bottom: 2.5rem;
                        }
                        &--select-container {
                          .select {
                            height: 5rem;
                            padding-right: 4rem;
                            .source {
                              .logo {
                                width: 2.5rem;
                                height: 2.5rem;
                                object-fit: inherit;
                                margin-bottom: 0;
                              }
                              p {
                                font-size: 2rem;
                                margin-bottom: 0;
                              }
                            }
                            .icon {
                              width: 2.6rem;
                              height: 2.6rem;
                              font-size: 1.2rem;
                            }
                          }
                        }
                      }
                      &__see-all-btn {
                        font-size: 2.1rem;
                      }
                    }
                    .ant-input {
                      font-size: 2.1rem;
                      height: 6.2rem;
                      padding: 1.8rem 2rem;
                    }
                    .pdb-news-sources-date-filters {
                      .ant-calendar-picker {
                        .anticon {
                          width: 2.5rem;
                          height: 2.5rem;
                          top: 3.1rem;
                        }
                      }
                    }
                    .tagify {
                      min-height: 6.2rem;
                      padding: 0;
                      &__input {
                        font-size: 2.1rem;
                        height: 6.2rem;
                        padding: 1.8rem 2rem;
                      }
                    }
                  }
                }
                .sources-filter-container-footer {
                  flex-direction: column;
                  gap: 1.5rem;
                  &__btn {
                    width: 100%;
                    height: 6.8rem;
                    font-size: 2.1rem;
                    margin: 0;
                  }
                }
              }
              .tab-management-container {
                &__body {
                  height: calc(100vh - 58.5rem);
                  @include respond(phone-x-small) {
                    &::-webkit-scrollbar {
                      display: none;
                    }
                  }
                  &--section {
                    &--header {
                      margin-bottom: 2.5rem;
                      .title {
                        font-size: 2.3rem;
                        line-height: 2.8rem;
                      }
                      .btn {
                        font-size: 1.8rem;
                        width: max-content;
                        height: 5rem;
                        padding: 0 2rem;
                        .icon {
                          font-size: 1.5rem;
                        }
                      }
                    }
                    .all-news-sources-dropdown {
                      .tagify {
                        min-height: 6.2rem;
                        padding: 0 2rem;
                        &__input {
                          font-size: 2.1rem;
                          height: 6.2rem;
                          padding: 1.8rem 0;
                        }
                      }
                      &__see-all-btn {
                        font-size: 2.1rem;
                      }
                    }
                    &--input {
                      font-size: 2.1rem;
                      min-height: 6.2rem;
                      padding: 0 2rem;
                    }
                    .privacy-settings {
                      &__header {
                        &--name {
                          img {
                            width: 2.5rem;
                            margin-top: -0.5rem;
                          }
                          p {
                            font-size: 2.3rem;
                            line-height: 2.8rem;
                          }
                        }
                        &--select {
                          .ant-select-selector {
                            min-width: 16rem;
                            height: 5rem !important;
                            .ant-select-selection-item {
                              font-size: 1.8rem;
                              .select-item {
                                display: flex;
                                font-size: 1.8rem;
                                img {
                                  margin-top: 0rem;
                                }
                                .white-icon {
                                  display: block !important;
                                  width: 1.8rem !important;
                                }
                                .black-icon {
                                  display: none !important;
                                }
                              }
                            }
                          }
                          .ant-select-arrow {
                            .anticon {
                              font-size: 1.5rem;
                              svg {
                                fill: $color-white;
                              }
                            }
                          }
                        }
                      }
                      &__body {
                        margin-top: 2rem;
                        p {
                          font-size: 1.8rem;
                          line-height: 2.5rem;
                        }
                      }
                    }
                    &--search-user {
                      min-height: 6.2rem;
                      .tagify {
                        min-height: 6.2rem;
                        padding: 0;
                        max-height: 100%;
                        &__input {
                          font-size: 2.1rem;
                          height: 6.2rem;
                          padding: 1.8rem 2rem;
                        }
                        &__tag {
                          > div {
                            display: flex;
                            align-items: center;
                          }
                          &--avatar-wrap {
                            margin-bottom: 0.2rem;
                            min-width: 3rem;
                            min-height: 3rem;
                            max-width: 3rem;
                            max-height: 3rem;
                          }
                          &--tag-text {
                            font-size: 1.8rem;
                            line-height: normal;
                          }
                          .tagify__tag__removeBtn {
                            width: 2rem;
                            height: 2rem;
                            padding: 0;
                            &::after {
                              font-size: 3.2rem;
                              line-height: 3rem;
                              
                            } 
                          }
                        }
                      }
                      .btn {
                        font-size: 1.8rem;
                        width: 22rem;
                        height: 4.4rem;
                        .icon {
                          font-size: 1.6rem;
                        }
                      }
                    }
                    &--active-admin {
                      .header {
                        h2 {
                          font-size: 2.3rem;
                          line-height: 2.8rem;
                        }
                      }
                      .body {
                        .message {
                          font-size: 2.3rem;
                          line-height: 2.8rem;
                        }
                      }
                    }
                  }
                }
                &__footer {
                  .apply-btn {
                    width: 100%;
                    height: 6.8rem;
                    font-size: 2.1rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
