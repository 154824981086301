<template>
  <a-drawer
    :title="false"
    placement="bottom"
    :visible="visibleSourceFilterDrawer"
    class="source-filter-drawer"
    :closable="false"
  >
    <div class="source-filter-container">
      <div class="source-filter-container__header">
        <p>Search Filters</p>
        <i class="icon icon-add_plus" @click="closeSourceDrawer()"></i>
      </div>
      <div class="source-filter-container__body">
        <discover-source-filter @closeDrawer="closeSourceDrawer()"></discover-source-filter>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import DiscoverSourceFilter from "./DiscoverSourceFilter.vue";

export default {
  components: {
    DiscoverSourceFilter,
  },
  props: {
    visibleSourceFilterDrawer: Boolean,
    updateTopicList: Boolean,
  },
  mounted(){
    this.emitter.on('closeFilterBar', () => {
      this.closeSourceDrawer();
    })
  },
  methods: {
    closeSourceDrawer() {
      this.$emit("close-sources-filter-drawer", false);
    },
  },
};
</script>

<style lang="scss">
.source-filter-drawer {
  .ant-drawer-content-wrapper {
    height: calc(100vh - 29rem) !important;
    .ant-drawer-content {
      overflow: hidden;
      .ant-drawer-wrapper-body {
        .ant-drawer-body {
          height: 100%;
          padding: 0;
          .source-filter-container {
            height: 100%;
            &__header {
              padding: 3rem 2.5rem 2.5rem;
              background-color: $color-white;
              display: flex;
              align-items: center;
              justify-content: space-between;
              p {
                font-size: 3.1rem;
                font-family: $font-primary-bold;
                line-height: normal;
                margin-bottom: 0;
              }
              .icon {
                transform: rotate(45deg);
                display: block;
                cursor: pointer;
                font-size: 2.5rem;
                opacity: 1;
                color: $color-black;
              }
            }
            &__body {
              .discover-soursce-filter-container {
                &__title {
                  display: none;
                }
                &__discover-soursce-filter-tabs {
                  background-color: $color-white;
                  height: 100vh;
                  .ant-tabs-bar {
                    padding: 2.5rem 2.8rem 0;
                    .ant-tabs-nav-container {
                      margin-left: 0;
                      .ant-tabs-tab-arrow-show {
                        background-color: $color-primary;
                        border-radius: 100%;
                        height: 2rem;
                        width: 2rem;
                        top: 50%;
                        transform: translateY(-97%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 1.8rem;
                        .anticon {
                          font-size: 1.2rem;
                          color: $color-white;
                        }
                        .ant-tabs-tab-prev-icon,
                        .ant-tabs-tab-next-icon {
                          width: 100%;
                          height: 100%;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        }
                      }
                      .ant-tabs-nav-wrap {
                        .ant-tabs-nav-scroll {
                          .ant-tabs-nav {
                            .ant-tabs-tab {
                              font-size: 2.1rem;
                              padding-bottom: 1.8rem;
                              color: $color-black;
                              opacity: 0.5;
                            }
                            .ant-tabs-tab-active {
                              color: $color-black;
                              opacity: 1;
                            }
                            .ant-tabs-ink-bar {
                              height: 0.5rem;
                              .clear-filter-btn {
                                opacity: 1;
                                color: $color-black;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .ant-tabs-extra-content {
                    .clear-filter-btn {
                      font-size: 2.1rem;
                      margin-top: 0.4rem;
                    }
                  }
                  .ant-tabs-content {
                    .ant-tabs-tabpane {
                      overflow-y: auto;
                      max-height: initial;
                      height: calc(100vh - 38rem);
                      padding: 0 2.8rem;
                      overflow-x: hidden;
                      -webkit-overflow-scrolling: touch;
                      overscroll-behavior: auto;
                      .discover-soursce-filter {
                        &__body {
                          &--included-excluded-sources-container {
                            padding: 4.2rem 2.5rem !important;
                            background-color: $color-white;
                            border-right: none;
                            border-bottom: 1px solid #c5c6cc;
                            .input-field {
                              .title {
                                font-size: 2.1rem;
                                line-height: 3rem;
                                margin-bottom: 2.2rem;
                                color: $color-black;
                                opacity: 1;
                              }
                              .all-news-sources-dropdown {
                                &__input {
                                  font-size: 2.1rem;
                                  line-height: 3.5rem;
                                  padding: 1.4rem 1.8rem;
                                  height: 6.2rem;
                                }
                                &__see-all-btn {
                                  font-size: 2.1rem;
                                }
                                &__dropdown {
                                  max-height: 30rem;
                                  margin-top: -4.4rem !important;
                                  .message {
                                    font-size: 2.1rem;
                                    line-height:  normal;
                                    padding: 2rem;
                                  }
                                  &--item {
                                    padding: 1.5rem 2rem !important;
                                    p {
                                      font-size: 2.3rem;
                                      line-height: 2.8rem;
                                    }
                                    .ant-checkbox-wrapper {
                                      .ant-checkbox {
                                        .ant-checkbox-inner {
                                          height: 17px;
                                          width: 17px;
                                          &::after {
                                            width: 5px;
                                            height: 9px;
                                          }
                                        }
                                      }
                                    }
                                    .ant-checkbox-wrapper-checked {
                                      .ant-checkbox-checked {
                                        .ant-checkbox-inner {
                                          height: 17px;
                                          width: 17px;
                                        }
                                      }
                                    }
                                  }
                                }
                                &__selected-sources {
                                  p {
                                    font-size: 2.3rem;
                                    line-height: 2.8rem;
                                    margin-bottom: 2.5rem;
                                  }
                                  &--select-container {
                                    max-height: 100%;
                                    .select {
                                      height: 5rem;
                                      padding-right: 4rem;
                                      .source {
                                        .logo {
                                          width: 2.5rem;
                                          height: 2.5rem;
                                          object-fit: inherit;
                                          margin-bottom: 0;
                                        }
                                        p {
                                          font-size: 2rem;
                                          margin-bottom: 0;
                                          line-height: normal;
                                        }
                                      }
                                      .icon {
                                        width: 2.6rem;
                                        height: 2.6rem;
                                        font-size: 1.2rem;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          &--date-filters-container {
                            border-top: none;
                            border-right: none;
                            padding: 4.2rem 2.5rem !important;
                            background-color: $color-white;
                            .input-field {
                              .title {
                                font-size: 2.1rem;
                                line-height: 3rem;
                                margin-bottom: 2.2rem;
                                color: $color-black;
                                opacity: 1;
                              }
                              .pdb-news-sources-date-filters,
                              .pdb-reserch-date-filters {
                                .ant-calendar-picker {
                                  .ant-input {
                                    font-size: 2.1rem;
                                    line-height: 3.5rem;
                                    padding: 1.4rem 1.8rem;
                                    height: 6.2rem;
                                  }
                                  .anticon {
                                    width: 2.5rem;
                                    height: 2.5rem;
                                  }
                                }
                              }
                            }
                            .apply-btn {
                              width: 100%;
                              height: 6.8rem;
                              font-size: 2.1rem;
                              position: relative !important;
                              margin-bottom: 0;
                              bottom: -1rem;
                              right: 0;
                            }
                          }
                          &--explore-newly-container {
                            background-color: $color-white;
                            padding: 4.2rem 2.5rem !important;
                            border-right: none;
                            h1 {
                              font-size: 2.1rem;
                              line-height: 3.5rem;
                              color: $color-black;
                              opacity: 1;
                            }
                            .request-news-source-container {
                              .title {
                                font-size: 2.1rem;
                                line-height: 3.5rem;
                                color: $color-black;
                                opacity: 1;
                                padding-right: 2rem;
                                font-family: $font-primary;
                              }
                              .ant-form {
                                .ant-row {
                                  margin-bottom: 2rem;
                                  .ant-col {
                                    padding: 0 !important;
                                    .ant-form-item-control-input {
                                      .ant-form-item-control-input-content {
                                        label {
                                          font-size: 2.1rem;
                                          line-height: 3rem;
                                          margin-bottom: 2.2rem;
                                          margin-top: 0.5rem;
                                          color: $color-black;
                                          opacity: 1;
                                          span {
                                            opacity: 0.5;
                                          }
                                        }
                                        .https-field {
                                          height: 6.3rem;
                                          padding: 0 1.6rem;
                                          p,
                                          input {
                                            font-size: 2.1rem;
                                            font-family: $font-primary-regular;
                                            &::placeholder {
                                              opacity: 0.5;
                                              color: $color-black;
                                            }
                                          }
                                        }
                                        textarea {
                                          height: 10.4rem;
                                          padding: 1.6rem;
                                          font-size: 2.1rem;
                                          font-family: $font-primary-regular;
                                        }
                                        .error {
                                          font-size: 2rem;
                                          font-family: $font-primary-regular;
                                          line-height: 2.5rem;
                                          margin-top: 0.5rem;
                                        }
                                      }
                                    }
                                  }
                                }
                                .request-btn {
                                  width: 100%;
                                  height: 6.8rem;
                                  font-size: 2.1rem;
                                  margin-top: 3rem;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
