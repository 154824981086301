<template>
  <div class="manage-topics-roles-section">
    <div class="manage-topics-roles-section__section">
      <div class="manage-topics-roles-section__section--header">
        <h1 class="title">Topic members</h1>
      </div>
      <div class="manage-topics-roles-section__section--search-users-dropdown">
        <input
          type="text"
          @focus="visibleDropdown"
          placeholder="Search users"
          class="manage-topics-roles-section__section--search-users-dropdown--input"
        />
        <div
          v-if="openDropdown"
          class="manage-topics-roles-section__section--search-users-dropdown--dropdown"
          @mousedown.stop
        >
          <!-- <div class="loader">
            <the-loader />
          </div> -->
          <!-- <p class="message">
            No users found.
          </p> -->
          <div
            v-for="(user) in Array.from(users.values())"
            :key="user?.id"
            class="manage-topics-roles-section__section--search-users-dropdown--dropdown--item"
          >
            <div class="image">
              <img :src="user?.profile_picture" alt="User image" />
            </div>
            <p>{{ user?.name }}</p>
            <a-checkbox v-model:checked="user.isChecked" @change="selectUser(user)"/>
          </div>
        </div>
      </div>
      <div class="manage-topics-roles-section__section--active-admin">
        <div class="header">
          <h1 class="title">Selected members: {{Array.from(selectedUsers.values()).length}}</h1>
        </div>
        <div class="body">
          <div class="add-user-card-container">
            <template v-if="Array.from(selectedUsers.values()).length == 0">
              <p class="message">No members selected</p>
            </template>
            <template v-else>
              <div v-for="user in Array.from(selectedUsers.values())" :key="user?.id" class="add-user-card">
                <div class="add-user-card__image">
                  <img
                    :src="user?.profile_picture"
                    alt="profile_picture"
                  />
                </div>
                <div class="add-user-card__content">
                  <div class="add-user-card__content--name">
                    <h1>{{user?.name}}</h1>
                    <p v-if="user?.status">Pending</p>
                  </div>
                  <a-select
                    class="add-user-card__content--select"
                    v-model:value="user.role"
                    :options="AdminOptions"
                  ></a-select>
                  <button @click.stop="removeUser(user)" class="add-user-card__content--close-btn">
                    <i class="icon icon-add_plus"></i>
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import TheLoader from "../../BaseComponents/TheLoader.vue";

export default {
  components: {
    // TheLoader,
  },
  props:{
    userOptions:{
      type: Array,
      default:() =>([])
    },
    module: {
      type: String,
      default: () => ('AddTopicModule')
    }
    
  },
  data() {
    return {
      AdminOptions: [
        {
          label: 'Admin',
          value: 'admin'
        },
        {
          label: 'Editor',
          value: 'editor'
        },
        {
          label: 'Contributor',
          value: 'contributor'
        }
      ],
      openDropdown: false,
      users: new Map(),
      selectedUsers:new Map(),
    };
  },
  watch:{
    userOptions:{
      handler(options){
        this.users = this.serializeData(options)
      }, 
      immediate:true
    },
  },
  mounted(){
    this.emitter.on('clear-manage-topic-roles', ()=>{
      this.clearAll()
    })
  },
  methods: {
    visibleDropdown() {
      setTimeout(() => {
        this.openDropdown = true;
      }, 200);
    },
    hideDropdown(){
      this.openDropdown = false
    },
    selectUser(user){
      if (user?.isChecked)
        this.selectedUsers.set(user?.id, user)
      else
        this.selectedUsers.delete(user?.id)   

      this.sendData()
    },
    serializeData(userOptions){
      return new Map(userOptions.map(user=>[user?.id, {
        ...user,
        name: user?.label || user?.full_name,
        profile_picture: user?.icon || user?.profile_picture,
        role: user?.role ?? 'editor'
      }]))
    },
    removeUser(user){
      this.module === 'AddTopicModule' ? this.removeUserLocally(user) : this.removeUserRemotely(user)
      this.sendData()
    },
    removeUserLocally(user){
      this.selectedUsers.delete(user?.id)
      this.users.set(user?.id, {...user, isChecked: false})
    },
    removeUserRemotely(user){console.log(user)},
    sendData(){
      this.$emit('fetchSelectedUser', Array.from(this.selectedUsers.values()))
    },
    clearAll(){
      this.users =  new Map()
      this.selectedUsers = new Map()
    }
  },
};
</script>

<style lang="scss">
.manage-topics-roles-section {
  margin-top: 3rem;
  &__section {
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid $color-dark-grey-5;
    &--msg {
      color: red;
    }
    &--header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 1.2rem;
      .title {
        font-size: 1.6rem;
        font-family: $font-primary-medium;
        line-height: 2.2rem;
        margin-bottom: 0;
        color: $color-black;
      }
    }
    &--search-users-dropdown {
      position: relative;
      &--input {
        border-radius: 0.5rem !important;
        font-size: 1.4rem;
        border: 1px solid #c5c6cc;
        font-family: $font-primary;
        width: 100%;
        height: 4rem;
        padding: 1rem;
        outline: none;
        color: $color-black;
        &::placeholder {
          opacity: 0.4;
          color: $color-black;
        }
      }
      &--dropdown {
        position: absolute;
        width: 100%;
        background-color: $color-white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        border-end-end-radius: 0.5rem;
        border-end-start-radius: 0.5rem;
        max-height: 20rem;
        overflow-y: auto;
        z-index: 2;
        top: 4rem;
        .message {
          cursor: default;
          font-size: 1.4rem;
          font-family: $font-primary;
          line-height: normal;
          margin: 1.8rem 0;
          color: $color-black;
          text-align: center;
          opacity: 0.8;
        }
        &--loader {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1rem 0 !important;
          .ant-spin {
            .anticon {
              font-size: 2rem !important;
            }
          }
        }
        &--item {
          padding: 1rem !important;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-bottom: 1px solid $color-dark-grey-5;
          cursor: pointer;
          gap: 1rem;
          &:last-child {
            border-bottom: none;
          }
          .image {
            width: 3rem;
            height: 3rem;
            border-radius: 100%;
            overflow: hidden;
            img {
              background-color: #80808040;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .ant-checkbox-wrapper {
            margin-left: auto;
            font-size: 1.6rem;
            font-family: $font-secondary;
            width: fit-content;
            margin-bottom: 0;
            line-height: 2rem;

            .ant-checkbox {
              .ant-checkbox-inner {
                border: 0.18rem solid rgba(128, 128, 128, 0.377);
                height: 1.8rem;
                width: 1.8rem;
                border-radius: 0;
                &::after {
                  width: 0.6rem;
                  height: 0.9rem;
                }
              }

              .ant-checkbox-input {
                width: fit-content;
                height: 100% !important;
              }
            }
          }

          .ant-checkbox-wrapper-checked {
            .ant-checkbox-checked {
              .ant-checkbox-inner {
                background-color: $color-primary;
                border-color: $color-primary;
                height: 1.8rem;
                width: 1.8rem;
                border-radius: 0;
              }
            }
          }
          p {
            margin: 0 !important;
            padding: 0 !important;
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            line-height: normal;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        .loader {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1.8rem 0;
          .anticon {
            font-size: 2.5rem !important;
          }
        }
      }
      &--dropdown::-webkit-scrollbar-track {
        background-color: $color-white;
      }
      &--dropdown::-webkit-scrollbar {
        width: 4px;
        background-color: $color-white;
        border-radius: 3rem;
      }
      &--dropdown::-webkit-scrollbar-thumb {
        background-color: $color-primary;
        border-radius: 3rem;
      }
    }
    &--active-admin {
      margin-top: 2.6rem;
      .header {
        margin-bottom: 1.8rem;
        .title {
          font-size: 1.4rem;
          font-family: $font-primary;
          line-height: 2.2rem;
          margin-bottom: 1.8rem;
          color: $color-black;
        }
      }
      .body {
        padding-bottom: 1.5rem;
        .message {
          font-size: 1.4rem;
          text-align: center;
          font-family: $font-primary;
          display: block;
          margin: 0 auto 2rem;
          opacity: 0.8;
        }
      }
      .add-user-card-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        .add-user-card {
          display: flex;
          align-items: center;
          gap: 0.8rem;
          width: 100%;
          &__image {
            width: 4rem;
            height: 4rem;
            overflow: hidden;
            border-radius: 100%;
            img {
              background-color: #80808040;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          &__content {
            display: flex;
            align-items: center;
            gap: 0.8rem;
            width: calc(100% - 5.3rem);
            &--name {
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              gap: 0.4rem;
              h1 {
                font-size: 1.6rem;
                font-family: $font-primary-medium;
                line-height: normal;
                margin-bottom: 0;
                color: $color-black;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* number of lines to show */
                line-clamp: 1;
                -webkit-box-orient: vertical;
              }
              p {
                font-size: 1rem;
                line-height: normal;
                height: 1.7rem;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 0.9rem;
                border-radius: 1rem;
                background-color: #ec424226;
                color: #ec4242;
                margin-bottom: 0;
                font-family: $font-primary;
              }
            }
            &--select {
              margin-left: auto;
              box-shadow: none;
              .ant-select-selector {
                height: 3rem !important;
                background-color: $color-primary;
                border: none;
                font-size: 1.4rem;
                line-height: normal;
                color: $color-white;
                padding: 0 1.5rem;
                box-shadow: none !important;
                border-radius: 0.5rem !important;
                font-family: $font-primary-medium;
                .ant-select-selection-search {
                  right: 2.6rem;
                  .ant-select-selection-search-input {
                    height: 100%;
                  }
                }
                .ant-select-selection-item {
                  font-size: 1.2rem;
                  padding-right: 2rem;
                  line-height: normal;
                }
              }
              .ant-select-arrow {
                right: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .anticon {
                  color: $color-white;
                  font-size: 1.1rem;
                }
              }
            }
            &--close-btn {
              height: 3rem;
              width: 3rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              outline: none;
              border-radius: 0.5rem !important;
              background-color: rgb(239, 239, 239);
              cursor: pointer;
              .icon {
                display: block;
                transform: rotate(45deg);
                font-size: 1.3rem;
                color: $color-black;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}
</style>
